import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import AddIcon from '@mui/icons-material/AddTwoTone';


const ActionButton = ({ onClick, type, sx }) => {

	const get_icon = ({ type, ...rest }) => {
		switch (type) {
			case 'delete':
				return <DeleteIcon {...rest} />
			case 'move_up':
				return <KeyboardDoubleArrowUpIcon {...rest} />
			case 'move_down':
				return <KeyboardDoubleArrowDownIcon {...rest} />
			case 'add':
				return <AddIcon />
			default:
				return null
		}
	}

	return (
		<IconButton color="primary"
			size="large"
			onClick={onClick}
			sx={sx}>
			{get_icon({ type, sx: { fontSize: '1.3rem' } })}
		</IconButton>
	)
}

export default ActionButton