import React, { useState } from 'react';

const wait = (time) => new Promise((resolve) => setTimeout(() => resolve(), time));

const ModalsContext = React.createContext();

function ModalsProvider(props) {
	const [current, current_set] = useState('');
	const [data, data_set] = useState({});

	const [opened, opened_set] = useState(false);

	const store = {
		show(current, data = {}) {
			opened_set(true);
			current_set(current);
			data_set(data);
		},
		async hide() {
			opened_set(false);

			await wait(400);

			current_set('');
			data_set({});
		},
		get modal() {
			return current;
		},
		get data() {
			return data;
		},
		set data(new_data = {}) {
			data_set(new_data);
		},
		get opened() {
			return opened;
		},
	};

	return <ModalsContext.Provider value={store}>{props.children}</ModalsContext.Provider>;
}

export default ModalsContext;
export { ModalsProvider };
