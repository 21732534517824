import React, { useContext, useEffect, useState } from 'react';

import UserContext from './user.jsx';

import api_organizations from '../api/organizations.js';

const OrganizationsContext = React.createContext();


function OrganizationsProvider ({ children }) {
	const { session } = useContext(UserContext);
	const [organizations, set_organizations] = useState([]);

	useEffect(() => {
		update_list()
	}, [1]);

	const update_list = async () => {
		try {
			const res = await api_organizations.get_list({ session });
			set_organizations(res);
			return res
		} catch (e) {
			console.log(e)
		}
	};

	const store = {
		get list () {
			return organizations;
		},
		async add (data) {
			await api_organizations.create({ data, session });
		},
		async edit (uid, data) {
			await api_organizations.update({ uid, data, session });
		},
		async get_by_uid (uid) {
			return await api_organizations.get_by_id({ uid, session });
		},
		async remove (uid, index) {
			await api_organizations.delete({ uid, session });
			organizations.splice(index, 1);
			set_organizations(organizations);
		},
		update_list,
	};

	return <OrganizationsContext.Provider value={store}>{children}</OrganizationsContext.Provider>;
}

export default OrganizationsContext;
export { OrganizationsProvider };
