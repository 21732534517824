import React from 'react';
import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Container, CssBaseline } from '@mui/material';

// project imports
import Sidebar from './Sidebar';
import { drawerWidth } from '../store/constant';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme }) => ({
	...theme.typography.mainContent,
	...{
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.shorter + 200,
		}),
		margin: 0,
		borderBottomLeftRadius: 0,
		borderBottomRightRadius: 0,
		width: `calc(100% - ${drawerWidth}px)`,
		minHeight: '100vh',
	},
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
	const theme = useTheme();

	return (
		<Box sx={{ display: 'flex' }}>
			<CssBaseline />

			{/* drawer */}
			<Sidebar />

			{/* main content */}
			<Main theme={theme}>
				<Container sx={{ px: { xs: 0 } }}>
					<Outlet />
				</Container>
			</Main>
		</Box>
	);
};

export default MainLayout;
