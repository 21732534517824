import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { Stack, Button, Typography } from '@mui/material';
import 'react-quill/dist/quill.snow.css';


const textarea_wrap_styles = {
	'position': 'relative',
	'& .quill': {
		'marginTop': '30px',
		'bgcolor': 'grey.50',
		'borderRadius': '12px',
		'& .ql-toolbar': {
			bgcolor: 'grey.100',
			borderColor: 'primary.light',
			borderTopLeftRadius: '12px',
			borderTopRightRadius: '12px',
		},
		'& .ql-container': {
			'borderColor': 'primary.light',
			'borderBottomLeftRadius': '12px',
			'borderBottomRightRadius': '12px',
			'& .ql-editor': {
				minHeight: 135,
			},
		},
	},
}

const RawButton = ({ on_click_raw }) => (
	<Button onClick={on_click_raw} sx={{ position: 'absolute', top: '0', right: 0 }}>
		Raw
	</Button>
);

const WysiwygTextarea = ({ onChange, value, label, placeholder }) => {
	const [editor_html, set_editor_html] = useState(value);
	const [raw_html, set_raw_html] = useState(value);
	const [show_raw, set_show_raw] = useState(false);

	const sync_views = (from_raw) => {
		if (from_raw) set_editor_html(raw_html);
		else set_raw_html(editor_html);
	};

	const handle_change = (html) => {
		set_editor_html(html);
		onChange(html);
	};

	const handle_change_raw = (html) => {
		set_raw_html(html);
		onChange(html);
	};

	const handle_click_show_raw = (event) => {
		event.preventDefault();
		set_show_raw(!show_raw);
		sync_views(show_raw);
	};

	useEffect(() => {
		set_editor_html(value)
	}, [value]);


	return (
		<Stack
			spacing={5}
			sx={textarea_wrap_styles}>
			<RawButton on_click_raw={handle_click_show_raw} />
			<Typography variant="h5">{label}</Typography>
			{show_raw ? (
				<textarea
					className={'raw-editor'}
					onChange={(e) => handle_change_raw(e.target.value)}
					value={raw_html}
					multiple
					style={{ minHeight: '160px', width: '100%', marginTop: '30px' }}
				/>
			) : (
				<ReactQuill onChange={handle_change} value={editor_html} theme={'snow'} placeholder={placeholder} />
			)}
		</Stack>
	);
};

export default WysiwygTextarea;
