import React, { useContext } from 'react';

import ModalsContext from 'src/context/modals.jsx';
import SimpleModal from 'src/berry/ui-component/modals/SimpleModals.jsx';

import ItemDelete from 'src/modals/item_delete.jsx';
import AddRegionForm from 'src/components/dahsboard_regions/add_region_form';


const _modal2components = new Map([
	['file_delete', { content: <ItemDelete />, title: 'Delete file' }],
	['species_delete', { content: <ItemDelete />, title: 'Delete species' }],
	['organization_delete', { content: <ItemDelete />, title: 'Delete organization' }],
	['take_action_delete', { content: <ItemDelete />, title: 'Delete "Take Action"' }],
	['regions_add', { content: <AddRegionForm />, title: 'Add region' }],
]);

const Modals = () => {
	const { modal, opened, hide } = useContext(ModalsContext);
	const component = _modal2components.get(modal);

	return <SimpleModal open={opened} on_close={hide} content={component?.content} title={component?.title} styles={component?.styles} />;
};

export default Modals;
