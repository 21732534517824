import SpeciesPage from 'src/components/dashboard_species/species_page.jsx';
import { FilesProvider } from 'src/context/files.jsx';
import { SpeciesProvider } from 'src/context/species';

const Page = () => (
  <FilesProvider>
    <SpeciesProvider>
      <SpeciesPage />
    </SpeciesProvider>
  </FilesProvider>
);

export default Page;
