import * as React from 'react';
import Layout from '../berry/layout/index.jsx';
import ThemeCustomization from 'src/berry/themes';
import { UserDeffer } from 'src/context/user.jsx';
import { ModalsProvider } from 'src/context/modals.jsx';

import Modals from 'src/controllers/modals.jsx';

const Dashboard = () => {
	return (
		<ThemeCustomization>
			<UserDeffer>
				<ModalsProvider>
					<Modals />
					<Layout />
				</ModalsProvider>
			</UserDeffer>
		</ThemeCustomization>
	);
};

export default Dashboard;
