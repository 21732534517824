import React, { useContext, useEffect, useState } from 'react';

import UserContext from './user.jsx';

import api_regions from '../api/regions.js';
import OrganizationsContext from './organization.jsx';
import TakeActionsContext from './take_action.jsx';
import SpeciesContext from './species.jsx';

const RegionsContext = React.createContext();

function RegionsProvider ({ children }) {
	const { session } = useContext(UserContext);
	const organizations_context = useContext(OrganizationsContext);
	const species_context = useContext(SpeciesContext);
	const take_actions_context = useContext(TakeActionsContext);
	
	const [regions, set_regions] = useState([]);

	useEffect(() => {
		update_list()
	}, [1]);

	const update_list = async () => {
		try {
			const res = await api_regions.get_list({ session });
			set_regions(res);
		} catch (e) {
			console.log(e)
		}
	};

	const get_items_by_uid = (uid_arr, type) => {
		const selected_array = [];
		let list;
		switch(type){
			case 'organizations':
				list = organizations_context.list;
			break;
			case 'species':
				list = species_context.list;
			break;
			case 'take_actions':
				list = take_actions_context.list;
			break;
		}

		if (!uid_arr) return selected_array

		uid_arr.forEach((uid) => {
			const item = list.find(({ uid: item_uid }) => item_uid === uid)
			if (item) {
				selected_array.push(item.data)
			}
		})

		return selected_array
	}

	const store = {
		get list () {
			return regions;
		},
		async edit (uid, data) {
			const updtated = await api_regions.update({ uid, data, session });
			await update_list()
			return updtated
		},
		async get_by_uid (uid) {
			return await api_regions.get_by_id({ uid, session });
		},
		get organizations () {
			return organizations_context.list
		},
		get species () {
			return species_context.list
		},
		get take_actions () {
			return take_actions_context.list;
		},
		update_list,
		get_items_by_uid,
	};

	return <RegionsContext.Provider value={store}>{children}</RegionsContext.Provider>;
}


export default RegionsContext;
export { RegionsProvider };
