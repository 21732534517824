import React, { useContext, useEffect, useState } from 'react';

import UserContext from './user.jsx';

import api_take_actions from '../api/take_actions.js';

const TakeActionsContext = React.createContext();

function TakeActionsProvider ({ children }) {
	const { session } = useContext(UserContext);
	const [take_actions, set_take_actions] = useState([]);

	useEffect(() => {
		update_list()
	}, [1]);

	const update_list = async () => {
		try {
			const res = await api_take_actions.get_list({ session });
			set_take_actions(res);
			return res
		} catch (e) {
			console.log(e)
		}
	};

	const store = {
		get list () {
			return take_actions;
		},
		async add (data) {
			await api_take_actions.create({ data, session });
		},
		async edit (uid, data) {
			await api_take_actions.update({ uid, data, session });
		},
		async get_by_uid (uid) {
			return await api_take_actions.get_by_id({ uid, session });
		},
		async remove (uid, index) {
			await api_take_actions.delete({ uid, session });
			take_actions.splice(index, 1);
			set_take_actions(take_actions);
		},
		update_list,
	};

	return <TakeActionsContext.Provider value={store}>{children}</TakeActionsContext.Provider>;
}

export default TakeActionsContext;
export { TakeActionsProvider };
