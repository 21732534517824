import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, ButtonBase, Chip, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }) => {
	const theme = useTheme();
	const { pathname } = useLocation();

	const [selected_item, set_selected_item] = useState('');
	const is_selected = selected_item === item.id;

	const Icon = item?.icon;
	const itemIcon = item?.icon ? (
		<Icon stroke={1.5} size={'20px'} style={{ color: is_selected ? theme.palette.secondary.main : theme.palette.text.primary }} />
	) : (
		<FiberManualRecordIcon
			sx={{
				color: is_selected ? theme.palette.secondary.main : theme.palette.text.primary,
				width: is_selected ? 8 : 6,
				height: is_selected ? 8 : 6,
			}}
			fontSize={level > 0 ? 'inherit' : 'medium'}
		/>
	);

	let itemTarget = '_self';
	if (item.target) {
		itemTarget = '_blank';
	}

	let listItemProps = { component: forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url} target={itemTarget} />) };
	if (item?.external) {
		listItemProps = { component: 'a', href: item.url, target: itemTarget };
	}

	const itemHandler = (id) => {
		set_selected_item(id);
	};

	// active menu item on page load
	useEffect(() => {
		const currentIndex = document.location.pathname
			.toString()
			.split('/')
			.findIndex((id) => id === item.id);

		currentIndex > -1 ? set_selected_item(item.id) : set_selected_item('');
		// eslint-disable-next-line
	}, [pathname]);

	const textColor = theme.palette.mode === 'dark' ? 'grey.400' : 'text.primary';
	const iconSelectedColor = theme.palette.mode === 'dark' ? 'text.primary' : 'secondary.main';

	return (
		<ListItemButton
			{...listItemProps}
			disabled={item.disabled}
			sx={{
				zIndex: 1201,
				borderRadius: '10px',
				mb: 0.5,
				pl: `${level * 24}px`,
				...(level === 1 &&
					theme.palette.mode !== 'dark' && {
						'&:hover': {
							background: theme.palette.secondary.light,
						},
						'&.Mui-selected': {
							'background': theme.palette.secondary.light,
							'color': iconSelectedColor,
							'&:hover': {
								color: iconSelectedColor,
								background: theme.palette.secondary.light,
							},
						},
					}),
			}}
			selected={is_selected}
			onClick={() => itemHandler(item.id)}
		>
			<ButtonBase sx={{ borderRadius: '10px' }} disableRipple={true}>
				<ListItemIcon
					sx={{
						minWidth: level === 1 ? 36 : 18,
						color: is_selected ? iconSelectedColor : textColor,
					}}
				>
					{itemIcon}
				</ListItemIcon>
			</ButtonBase>

			<ListItemText
				primary={
					<Typography variant={is_selected ? 'h5' : 'body1'} color="inherit">
						{item.title}
					</Typography>
				}
				secondary={
					item.caption && (
						<Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
							{item.caption}
						</Typography>
					)
				}
			/>

			{item.chip && (
				<Chip
					color={item.chip.color}
					variant={item.chip.variant}
					size={item.chip.size}
					label={item.chip.label}
					avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
				/>
			)}
		</ListItemButton>
	);
};

NavItem.propTypes = {
	item: PropTypes.object,
	level: PropTypes.number,
	parentId: PropTypes.string,
};

export default NavItem;
