import { memo } from 'react';

// material-ui
import { Typography } from '@mui/material';
import { IconChartArcs, IconFile3d, IconMap2, IconButterfly, IconBuildingCommunity, IconHandFinger } from '@tabler/icons';
// project imports
import NavGroup from './NavGroup';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const menu_items = [
	{
		id: 'dashboard',
		title: 'Dashboard',
		icon: IconChartArcs,
		type: 'group',
		children: [
			{
				id: 'species',
				title: 'Species of interest',
				type: 'item',
				url: '/dashboard/species',
				icon: IconButterfly,
			},
			{
				id: 'organizations',
				title: 'Area Contacts',
				type: 'item',
				url: '/dashboard/organizations',
				icon: IconBuildingCommunity,
			},
			{
				id: 'take_actions',
				title: 'Take Actions',
				type: 'item',
				url: '/dashboard/take_actions',
				icon: IconHandFinger,
			},
			{
				id: 'regions',
				title: 'Regions',
				type: 'item',
				url: '/dashboard/regions',
				icon: IconMap2,
			},
			{
				id: 'file_manager',
				title: 'File Manager',
				type: 'item',
				url: '/dashboard/file_manager',
				icon: IconFile3d,
			},
		],
	},
];

const MenuList = () => {
	const navItems = menu_items.map((item) => {
		switch (item.type) {
			case 'group':
				return <NavGroup key={item.id} item={item} />;
			default:
				return (
					<Typography key={item.id} variant="h6" color="error" align="center">
						Menu Items Error
					</Typography>
				);
		}
	});

	return <>{navItems}</>;
};

export default memo(MenuList);
