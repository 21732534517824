import React from 'react';
import { Box, Typography, Select, MenuItem } from '@mui/material';
import Table from './table';
import { array_delete_item, array_move } from 'src/utils';

const texts = new Map([
	['organizations',{list:'List of organizations', button:'Add organization'}],
	['species',{list:'List of species', button:'Add species'}],
	['take_actions',{list:'List of "Take Action"', button:'Add "Take Action"'}],
]);

const list = ({ fields, type, current_item, set_current_item, data }) => {
	const text = texts.get(type);
	const is_select_disabled = data.length === 0;

	const on_delete = async (event, index) => {
		event.stopPropagation();
		update_data_array(array_delete_item(current_item.data[type], index));
	};

	const handle_replace_section = async (index, direction) => {
		if (!type) return;
		update_data_array(array_move(current_item.data[type], index, direction));
	};

	const on_add = async (event) => {
		event.stopPropagation();
		const value = event.target.value;

		if (value) {
			update_data_array([...current_item.data[type], value]);
		}
	};

	const update_data_array = (new_arr) => {
		const new_item = { ...current_item, data: { ...current_item.data, [type]: new_arr } };
		set_current_item(new_item);
	};

	return (
		<Box sx={{ marginTop: '50px' }}>
			<Typography variant="h4" sx={{ marginBottom: '10px' }}>
				{text.list}
			</Typography>
			<Table on_move_up={(index) => handle_replace_section(index, -1)} on_move_down={(index) => handle_replace_section(index, 1)} on_delete={(event, index) => on_delete(event, index)} fields={fields} />
			<Select
				disabled={is_select_disabled}
				displayEmpty
				renderValue={() => <div>{text.button}</div>}
				onChange={on_add}
				value={'age'}
				sx={{ marginTop: '30px' }}
			>
				{data.map(({ uid, data: { name } }) => (
					<MenuItem key={uid} value={uid}>
						{name}
					</MenuItem>
				))}
			</Select>
		</Box>
	);
};

export default list;
