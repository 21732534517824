import PropTypes from 'prop-types';
import React from 'react';

// material-ui
import { CardContent, Divider, Grid, IconButton, Modal } from '@mui/material';

// project imports
import MainCard from '../cards/MainCard';

// assets
import CloseIcon from '@mui/icons-material/Close';

function getModalStyle() {
	return {
		top: '50%',
		left: '50%',
		transform: `translate(-50%, -50%)`,
	};
}

const Body = React.forwardRef(({ modalStyle, handleClose, content, title, styles }, ref) => (
	<div ref={ref} tabIndex={-1}>
		<MainCard
			style={{ ...modalStyle, ...styles }}
			sx={{
				position: 'absolute',
				width: { xs: 280, lg: 450 },
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
			}}
			title={title}
			content={false}
			secondary={
				<IconButton onClick={handleClose} size="large">
					<CloseIcon fontSize="small" />
				</IconButton>
			}
		>
			<CardContent>{content}</CardContent>
			<Divider />
		</MainCard>
	</div>
));

Body.propTypes = {
	modalStyle: PropTypes.object,
	handleClose: PropTypes.func,
};

export default function SimpleModal({ open, on_close, content, title, styles }) {
	const [modalStyle] = React.useState(getModalStyle);

	return (
		<Grid container justifyContent="flex-end">
			<Modal open={open} onClose={on_close} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
				<Body modalStyle={modalStyle} handleClose={on_close} content={content} title={title} styles={styles} />
			</Modal>
		</Grid>
	);
}
