import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import UserContext, { UserProvider } from './context/user.jsx';

import SignInPage from './pages/sign_in.jsx';
import Dashboard from './pages/dashboard.jsx';
import DashboardFileManager from './pages/dashboard_file_manager.jsx';
import DashboardSpecies from './pages/dashboard_species.jsx';
import DashboardSpeciesItem from './pages/dashboard_species_item.jsx';
import DashboardOrganizations from './pages/dashboard_organizations.jsx';
import DashboardOrganization from './pages/dashboard_organization.jsx';
import DashboardTakeActions from './pages/dashboard_take_actions.jsx';
import DashboardTakeAction from './pages/dashboard_take_action.jsx';
import DashboardRegions from './pages/dashboard_regions.jsx';
import DashboardRegion from './pages/dashboard_region.jsx';

import { ModalsProvider } from './context/modals.jsx';

const Index = () => {
	const navigate = useNavigate();
	const user_context = useContext(UserContext);

	if (!user_context.loaded) return <p>Loading...</p>;

	if (!user_context.user) {
		navigate('/sign_in');
	} else {
		navigate('/dashboard');
	}
};

export default function App() {
	return (
		<Router>
			<ModalsProvider>
				<UserProvider>
					<Routes>
						<Route exact path="/" element={<Index element />} />
						<Route path="/sign_in" element={<SignInPage />} />
						<Route path="/dashboard" element={<Dashboard />}>
							<Route path="species" element={<DashboardSpecies />} />
							<Route path="species/:uid" element={<DashboardSpeciesItem />} />
							<Route path="organizations" element={<DashboardOrganizations />} />
							<Route path="organizations/:uid" element={<DashboardOrganization />} />
							<Route path="take_actions" element={<DashboardTakeActions />} />
							<Route path="take_actions/:uid" element={<DashboardTakeAction />} />
							<Route path="regions" element={<DashboardRegions />} />
							<Route path="regions/:uid" element={<DashboardRegion />} />
							<Route path="file_manager" element={<DashboardFileManager />} />
						</Route>
					</Routes>
				</UserProvider>
			</ModalsProvider>
		</Router>
	);
}
