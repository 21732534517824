import { useContext, useRef } from 'react';
import FilesContext, { FilesProvider } from '../context/files';
import ModalsContext from '../context/modals';
import {
	CardContent,
	Fab,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
	CircularProgress,
} from '@mui/material';
import MainCard from '../berry/ui-component/cards/MainCard';
import Avatar from '../berry/ui-component/extended/Avatar';
import AddIcon from '@mui/icons-material/AddTwoTone';

import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { DateTime } from 'luxon';
import CONFIG from '../config.json';

const Body = ({ data }) => {
	const modals_context = useContext(ModalsContext);
	const files_context = useContext(FilesContext);

	const update_file_size = (bytes) => {
		if (bytes < 1024) {
			return bytes + 'b';
		} else if (bytes < 1048576) {
			return (bytes / 1024).toFixed(1) + 'kb';
		} else {
			return (bytes / 1048576).toFixed(1) + 'mb';
		}
	};

	const update_date_format = (time) => {
		return DateTime.fromMillis(time).toFormat('EEE, MMM d yyyy, HH:mm:ss');
	};

	const reduce_string = (str) => {
		return str.length > 70 ? str.slice(0, 70) + '...' : str;
	};

	return (
		<TableBody>
			{data.map(({ modified, name, size }, i) => {
				return (
					<TableRow key={i}>
						<TableCell>
							<Avatar src={`${CONFIG.api}/app_data/previews/${name}`} size="md" variant="rounded" />
						</TableCell>
						<TableCell sx={{ pl: 4 }} width="100%">
							{reduce_string(name)}
						</TableCell>
						<TableCell align="right">{update_file_size(size)}</TableCell>
						<TableCell align="right">{update_date_format(modified)}</TableCell>
						<TableCell align="center">
							<IconButton
								color="primary"
								size="large"
								onClick={() =>
									modals_context.show('file_delete', {
										clb: () => {
											files_context.remove(name);
										},
									})
								}
							>
								<DeleteIcon sx={{ fontSize: '1.3rem' }} />
							</IconButton>
							<IconButton
								color="secondary"
								size="large"
								onClick={() => window.open(`${CONFIG.api}/app_data/files/${name}`, '_blank', 'noopener,noreferrer')}
							>
								<VisibilityIcon sx={{ fontSize: '1.3rem' }} />
							</IconButton>
						</TableCell>
					</TableRow>
				);
			})}
		</TableBody>
	);
};

const Files = () => {
	const files_context = useContext(FilesContext);
	const file_input_ref = useRef();

	const on_change = (e) => {
		files_context.add(...e.target.files);
	};

	if (!files_context.list) return <CircularProgress size={100} sx={{ mx: 'auto', mt: 20, display: 'block' }} />;

	const title = (
		<CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end', p: 0 }}>
			<Typography variant="h4" sx={{ color: 'grey.900' }}>
				Files Manager
			</Typography>
			<Tooltip title="Add File" onClick={() => file_input_ref.current.click()}>
				<Fab color="primary" size="small" sx={{ boxShadow: 'none', width: 32, height: 32, minHeight: 32, float: 'right' }}>
					<AddIcon fontSize="small" />
				</Fab>
			</Tooltip>
			<input type="file" onChange={on_change} hidden ref={file_input_ref} multiple />
		</CardContent>
	);

	return (
		<MainCard content={false} title={title}>
			{files_context.list.length ? (
				<TableContainer>
					<Table style={{ whiteSpace: 'nowrap', width: '100%' }}>
						<TableHead>
							<TableRow>
								<TableCell align="center">Preview</TableCell>
								<TableCell sx={{ pl: 4 }} width="100%">
									Name
								</TableCell>
								<TableCell>Size</TableCell>
								<TableCell>Modified</TableCell>
								<TableCell align="center">Actions</TableCell>
							</TableRow>
						</TableHead>
						<Body data={files_context.list} />
					</Table>
				</TableContainer>
			) : (
				<Typography variant="subtitle1" sx={{ color: 'grey.900', my: 4 }} align="center">
					There are no files
				</Typography>
			)}
		</MainCard>
	);
};

const Page = () => {
	return (
		<FilesProvider>
			<Files />
		</FilesProvider>
	);
};

export default Page;
