import React, { useState, useContext, useEffect } from 'react';

import UserContext from './user.jsx';

import files_api from '../api/files.js';

const FilesContext = React.createContext();

function FilesProvider({ children }) {
	const [files, files_set] = useState([]);
	const { session } = useContext(UserContext);

	const store = {
		get list() {
			return files;
		},
		async add(...files) {
			await Promise.all(files.map((file) => files_api.upload({ file, session })));
			await update_files();
		},
		async remove(name) {
			await files_api.delete({ name, session });
			await update_files();
		},
	};

	const update_files = async () => {
		files_set(await files_api.list({ session }));
	};

	useEffect(() => {
		update_files();
	}, [1]);

	return <FilesContext.Provider value={store}>{children}</FilesContext.Provider>;
}

export default FilesContext;
export { FilesProvider };
