import SpeciesForm from 'src/components/dashboard_species/species_form';
import { FilesProvider } from 'src/context/files.jsx';
import { SpeciesProvider } from 'src/context/species';

const Page = () => (
	<FilesProvider>
		<SpeciesProvider>
			<SpeciesForm />
		</SpeciesProvider>
	</FilesProvider>
);

export default Page;
