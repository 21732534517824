
import React, { useContext } from 'react';

import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import MainCard from 'src/berry/ui-component/cards/MainCard';

import ModalsContext from 'src/context/modals';
import { useLocation, useNavigate } from 'react-router-dom';
import SpeciesContext from 'src/context/species';
import ActionButton from 'src/ui/action_button';


const Body = ({ data }) => {
	const modals_context = useContext(ModalsContext);
	const species_context = useContext(SpeciesContext);
	const navigate = useNavigate();

	const on_click = (event, uid, index) => {
		event.stopPropagation();
		modals_context.show('species_delete', {
			clb: () => {
				species_context.remove(uid, index);
			},
		});
	}

	const on_row_click = (uid) => {
		navigate(`/dashboard/species/${uid}`);
	}

	return (
		<TableBody>
			{data.map(({ data, uid }, index) => {
				return (
					<TableRow key={index} hover
						onClick={() => on_row_click(uid)}
						style={{ cursor: 'pointer' }}>
						<TableCell>{data?.name ?? ''}</TableCell>
						<TableCell>
							<div dangerouslySetInnerHTML={{ __html: data?.desc ?? '' }}></div>
						</TableCell>
						<TableCell>{data?.link ?? ''}</TableCell>
						<TableCell align="center"><ActionButton onClick={(event) => on_click(event, uid, index)} type="delete"/></TableCell>
					</TableRow>
				);
			})}
		</TableBody>
	);
};

const Species = () => {
	const species_context = useContext(SpeciesContext);
	const location = useLocation()
	const navigate = useNavigate();

	const on_add = () => navigate(`${location.pathname}/new`)

	return (
		<>
			<Button
				variant="contained"
				type="button"
				onClick={on_add}
				sx={{ mb: 2 }}
			>
				Add species
			</Button>
			{species_context?.list.length ? (
				<MainCard content={false}>
					<TableContainer >
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Name</TableCell>
									<TableCell>Description</TableCell>
									<TableCell>Link</TableCell>
									<TableCell align="center" sx={{ pr: 4 }}>
										Action
									</TableCell>
								</TableRow>
							</TableHead>
							<Body data={species_context.list} />
						</Table>
					</TableContainer>
				</MainCard>
			) : (
				<Typography variant="subtitle1" sx={{ color: 'grey.900' }} align="center">
					There are no species
				</Typography>
			)}
		</>
	)
}


const Page = () => {
	return (
		<Species />
	)
}

export default Page