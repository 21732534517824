import { useContext } from 'react';

import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import MainCard from 'src/berry/ui-component/cards/MainCard';

import { useNavigate } from 'react-router-dom';
import SpeciesContext from 'src/context/regions';
import RegionsContext from 'src/context/regions';
import ModalsContext from 'src/context/modals';

const List = ({ data }) => {

	if (!data?.length) return null

	return (
		<>
			{data.map((item, index) => (
				<div key={index}>
					{item?.name ?? ''}
				</div>
			))}
		</>
	)
}

const Body = ({ list }) => {
	const regions_context = useContext(SpeciesContext);
	const navigate = useNavigate();

	const on_row_click = (uid) => {
		navigate(`/dashboard/regions/${uid}`);
	}

	const get_items_by_uid = (uid_arr, type) => (
		regions_context.get_items_by_uid(uid_arr, type)
	)

	return (
		<TableBody>
			{list.map(({ data, uid }, index) => {
				return (
					<TableRow key={index} hover
						onClick={() => on_row_click(uid)}
						style={{ cursor: 'pointer' }}>
						<TableCell>{uid ?? ''}</TableCell>
						<TableCell>{data?.map_name ?? ''}</TableCell>
						<TableCell>{data?.popup_name ?? ''}</TableCell>
						<TableCell align="center"><List data={get_items_by_uid(data?.organizations, 'organizations')} /></TableCell>
						<TableCell align="center"><List data={get_items_by_uid(data?.species, 'species')} /></TableCell>
						<TableCell align="center"><List data={get_items_by_uid(data?.take_actions, 'take_actions')} /></TableCell>
					</TableRow>
				);
			})}
		</TableBody>
	);
};


const Regions = () => {
	const regions_context = useContext(RegionsContext);
	const modals_context = useContext(ModalsContext);

	const on_add = () => {
		modals_context.show('regions_add', {
			clb: async ({ uid }) => {
				await regions_context.edit(uid);
			}
		})
	}

	return (
		<>
			{/*<Button
				variant="contained"
				type="button"
				onClick={on_add}
				sx={{ mb: 2 }}
			>
				Add region
			</Button>*/}
			{regions_context?.list.length ? (
				<MainCard content={false}>
					<TableContainer >
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>UID</TableCell>
									<TableCell>Map name</TableCell>
									<TableCell>Popup name</TableCell>
									<TableCell align="center">Area&nbsp;Contacts</TableCell>
									<TableCell align="center">Species</TableCell>
									<TableCell align="center">Take&nbsp;Action</TableCell>
								</TableRow>
							</TableHead>
							<Body list={regions_context.list} />
						</Table>
					</TableContainer>
				</MainCard>
			) : (
				<Typography variant="subtitle1" sx={{ color: 'grey.900' }} align="center">
					There are no regions
				</Typography>
			)}
		</>
	)
}


const Page = () => {
	return (
		<Regions />
	)
}

export default Page
