import RegionsPage from 'src/components/dahsboard_regions/regions_page.jsx';
import { FilesProvider } from 'src/context/files.jsx';
import { OrganizationsProvider } from 'src/context/organization';
import { RegionsProvider } from 'src/context/regions';
import { SpeciesProvider } from 'src/context/species';
import { TakeActionsProvider } from 'src/context/take_action';

const Page = () => (
	<FilesProvider>
		<OrganizationsProvider>
			<TakeActionsProvider>
				<SpeciesProvider>
					<RegionsProvider>
						<RegionsPage />
					</RegionsProvider>
				</SpeciesProvider>
			</TakeActionsProvider>
		</OrganizationsProvider>
	</FilesProvider>
);

export default Page;
