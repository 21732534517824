
import { useForm } from 'react-hook-form';
import { Stack } from '@mui/material';

import MainCard from 'src/berry/ui-component/cards/MainCard.jsx';
import { gridSpacing } from 'src/berry/store/constant.jsx';
import InputLabel from 'src/berry/ui-component/extended/Form/InputLabel.jsx';
import FileSelector from '../file_selector/component.jsx';
import { useLocation, useNavigate } from 'react-router-dom';
import ControlRender from 'src/ui/controller_render.jsx';
import FormActionsButtons from 'src/ui/form_actions_buttons.jsx';
import OrganizationsContext from 'src/context/organization.jsx';
import { useContext, useEffect } from 'react';


const formsList = [
	{ name: 'name', label: 'Name', type: 'text', fullWidth: true },
	{ name: 'link', label: 'Link', type: 'text', fullWidth: true },
	{ name: 'desc', label: 'Short description', type: 'textarea' },
	{ name: 'email', label: 'Email', type: 'text', fullWidth: true },
	{ name: 'phone', label: 'Phone', type: 'text', fullWidth: true },
];

const initialData = { name: '', desc: '', link: '', email: '', phone: '' }


const OrganizationForm = () => {

	const navigate = useNavigate();
	const organizations_context = useContext(OrganizationsContext);
	const pathname = useLocation().pathname;
	const uid = pathname.split('/').pop();
	const isNew = uid === 'new';
	const { handleSubmit, control, setValue, getValues } = useForm({
		defaultValues: initialData
	});

	const back_to_list = () => navigate('/dashboard/organizations');

	const on_sumbit = async (data) => {
		if (data.name) {
			if (isNew) {
				await organizations_context.add(data)
			} else {
				await organizations_context.edit(uid, data)
			}
			back_to_list()
		}
	}

	useEffect(() => {
		if (isNew) return;
		get_item()
	}, [1]);

	const get_item = async () => {
		const item = await organizations_context.get_by_uid(uid)
		const data = item?.data

		for (const key in data) {
			setValue(key, data[key])
		}
	}

	return (
		<MainCard title="Add Area Contact">
			<form onSubmit={handleSubmit(on_sumbit)}>
				<Stack spacing={gridSpacing}>
					<ControlRender control={control} formsList={formsList} />
					<InputLabel>Image</InputLabel>
					<FileSelector
						on_change={(img) => setValue('img', img)}
						value={getValues('img')}
					/>
					<FormActionsButtons on_cancel={back_to_list} on_save={on_sumbit} />
				</Stack>
			</form>
		</MainCard>
	);
};

export default OrganizationForm;
