
import { useForm } from 'react-hook-form';
import { Stack } from '@mui/material';

import MainCard from 'src/berry/ui-component/cards/MainCard.jsx';
import { gridSpacing } from 'src/berry/store/constant.jsx';
import { useLocation, useNavigate } from 'react-router-dom';
import ControlRender from 'src/ui/controller_render.jsx';
import FormActionsButtons from 'src/ui/form_actions_buttons.jsx';
import RegionsContext from 'src/context/regions.jsx';
import { useContext, useEffect, useState } from 'react';
import List from './list';


const formsList = [
	{ name: 'map_name', label: 'Map name', type: 'text', fullWidth: true },
	{ name: 'popup_name', label: 'Popup name', type: 'text', fullWidth: true },
	{ name: 'more_species', label: '"More species" URL', type: 'text', fullWidth: true },
];

const initialData = { map_name: '', popup_name: '', more_species: '' };

const RegionForm = () => {
	const navigate = useNavigate();
	const regions_context = useContext(RegionsContext);
	const pathname = useLocation().pathname;
	const uid = pathname.split('/').pop();
	const isNew = uid === 'new';

	const { handleSubmit, control, setValue } = useForm({
		defaultValues: initialData
	});

	const [current_item, set_current_item] = useState(null);
	const [selected_organizations, set_selected_organizations] = useState([]);
	const [selected_take_actions, set_selected_take_actions] = useState([]);
	const [selected_speices, set_selected_species] = useState([]);

	const [not_selected_organizations, set_not_selected_organizations] = useState([]);
	const [not_selected_take_actions, set_not_selected_take_actions] = useState([]);
	const [not_selected_species, set_not_selected_species] = useState([]);

	const is_stores_initialized = regions_context.organizations.length > 0 && regions_context.species.length > 0

	const back_to_list = () => navigate('/dashboard/regions');

	const on_sumbit = async (data) => {
		if (isNew) return

		if (data.map_name) {
			await regions_context.edit(uid, { ...current_item.data, ...data })
			back_to_list()
		}
	}

	useEffect(() => {
		if (isNew) return;
		get_item()
	}, [1]);

	const get_item = async () => {
		const item = await regions_context.get_by_uid(uid);

		if (!item) return;

		const { data = {} } = item;
		item.data = { organizations: [], species: [], take_actions: [], ...data };

		set_current_item(item);

		Object.entries(data)
			.filter(([key]) => key !== 'organizations' && key !== 'species' && key !== 'take_actions')
			.forEach(([key, value]) => setValue(key, value));
	};

	useEffect(() => {
		if (current_item?.data && is_stores_initialized) {
			set_unselected_items()
			set_selected_items()
		}
	}, [current_item, regions_context.organizations, regions_context.species, regions_context.take_actions]);


	const set_selected_items = () => {
		const { data } = current_item

		set_selected_organizations(regions_context.get_items_by_uid(data.organizations, 'organizations'));
		set_selected_species(regions_context.get_items_by_uid(data.species, 'species'));
		set_selected_take_actions(regions_context.get_items_by_uid(data.take_actions, 'take_actions'));
	}

	const set_unselected_items = () => {
		const { data } = current_item

		set_not_selected_organizations(filter_items_by_uid(regions_context.organizations, data.organizations));
		set_not_selected_species(filter_items_by_uid(regions_context.species, data.species));
		set_not_selected_take_actions(filter_items_by_uid(regions_context.take_actions, data.take_actions));
	}

	const filter_items_by_uid = (all_items, uids) => {
		return all_items.filter(({ uid }) => !uids.includes(uid));
	}

	if (!current_item) return null

	return (
		<MainCard title="Add region">
			<form onSubmit={handleSubmit(on_sumbit)}>
				<Stack spacing={gridSpacing}>
					<ControlRender control={control} formsList={formsList} />

					<List
						type="organizations"
						current_item={current_item}
						set_current_item={set_current_item}
						fields={selected_organizations}
						data={not_selected_organizations}
					/>

					<List
						type="species"
						current_item={current_item}
						set_current_item={set_current_item}
						fields={selected_speices}
						data={not_selected_species}
					/>

					<List
						type="take_actions"
						current_item={current_item}
						set_current_item={set_current_item}
						fields={selected_take_actions}
						data={not_selected_take_actions}
					/>

					<FormActionsButtons on_cancel={back_to_list} on_save={on_sumbit} />
				</Stack>
			</form>
		</MainCard>
	);
}

export default RegionForm;
