import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import user_api from '../api/user';

const UserContext = React.createContext();

function UserProvider(props) {
	const navigate = useNavigate();
	const [cookies, cookies_set, cookies_remove] = useCookies(['session']);

	const [session, session_set] = useState(cookies.session || sessionStorage.getItem('session') || undefined);
	const [user, user_set] = useState(undefined);

	const [state, set_state] = useState('');

	const _set_session = (v) => {
		session_set(v);

		if (v) {
			cookies_set('session', v);
		} else {
			cookies_remove('session');
		}
	};

	const logout = () => {
		user_set(undefined);
		_set_session(undefined);
		navigate('/sign_in');
	};

	const login = async (login, password) => {
		const res = await user_api.login({ login, password });
		if (res.user && res.session) {
			user_set(res.user);
			_set_session(res.session);
			return true;
		}
	};

	const store = {
		get state() {
			return state;
		},
		set state(_) {},
		get loaded() {
			return state === 'loaded';
		},
		set loaded(_) {},
		get user() {
			return user;
		},
		set user(_) {},
		get session() {
			return session;
		},
		set session(_) {},
		logout,
		login,
	};

	useEffect(() => {
		if (state === 'loading') return;
		if (!session) {
			set_state('loaded');
			return;
		}
		if (user) {
			set_state('loaded');
			return;
		}

		set_state('loading');

		(async () => {
			let res;
			try {
				res = await user_api.resume_session(session);
				if (res) {
					user_set(res);
					set_state('loaded');
				}
			} catch (e) {
				_set_session(undefined);
				set_state('loaded');
			}
		})();
	}, [session]);

	return <UserContext.Provider value={store}>{props.children}</UserContext.Provider>;
}

function UserDeffer({ children }) {
	const context = useContext(UserContext);

	if (!context.user) {
		if (context.state === 'loading') {
			return <p>Resuming session...</p>;
		}
		return (
			<p>
				This page is available for authorized users only. Please <NavLink to="/sign_in">sign in</NavLink>
			</p>
		);
	}

	return children;
}

export default UserContext;
export { UserProvider, UserDeffer };
