import React, { useContext } from 'react';
import { Button, Typography, Box } from '@mui/material';
import ModalsContext from '../context/modals.jsx';

const ItemDelete = () => {
	const {
		hide,
		data: { clb },
	} = useContext(ModalsContext);

	const on_delete = () => {
		clb();
		hide();
	};

	return (
		<>
			<Typography variant="h5" textAlign={'center'}>
				Are you sure?
			</Typography>
			<Box textAlign="center">
				<Button variant="contained" type="button" onClick={on_delete} sx={{ mt: 2 }}>
					Delete
				</Button>
			</Box>
		</>
	);
};

export default ItemDelete;
