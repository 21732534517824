import TakeActionsPage from 'src/components/dashboard_take_actions/take_action_page';
import { FilesProvider } from 'src/context/files.jsx';
import { TakeActionsProvider } from 'src/context/take_action';

const Page = () => (
	<FilesProvider>
		<TakeActionsProvider>
			<TakeActionsPage />
		</TakeActionsProvider>
	</FilesProvider>
);

export default Page;
