import OrganizationsPage from 'src/components/dashboard_organizations/organization_page.jsx';
import { FilesProvider } from 'src/context/files.jsx';
import { OrganizationsProvider } from 'src/context/organization';

const Page = () => (
	<FilesProvider>
		<OrganizationsProvider>
			<OrganizationsPage/>
		</OrganizationsProvider>
	</FilesProvider>
);

export default Page;
