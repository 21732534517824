import post from './post.js';
import get from './get.js';

export default {
	get_list: ({ session }) => get('species/list', { session }),
	create: ({ session, data }) => post('species/create', { session, data }),
	delete: ({ session, uid }) => post('species/delete', { session, uid }),
	get_by_id: ({ session, uid }) => get('species/get', { session, uid }),
	update: ({ session, uid, data }) => post('species/update', { session, uid, data }),
};
