import React, { useContext, useEffect, useRef, useState } from 'react';
import {
	ImageList,
	ImageListItem,
	IconButton,
	ImageListItemBar,
	InputAdornment,
	TextField,
	Stack,
	Tooltip,
	Fab,
	Divider,
	Avatar,
	Box,
	Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/AddTwoTone';

import MainCard from 'src/berry/ui-component/cards/MainCard.jsx';
import CONFIG from 'src/config.json';
import FilesContext, { FilesProvider } from 'src/context/files.jsx';
import { search_by_name } from 'src/utils/index.js';

import DeleteIcon from '@mui/icons-material/Delete';

const video_types = new Set(['.mp4','.mov']);

const ModalFileSelector = ({ close, value, clb, type }) => {
	const file_input_ref = useRef();

	const { list } = useContext(FilesContext);
	const files_context = useContext(FilesContext);

	const [search_value, set_search_value] = useState('');

	const on_submit = (name) => {
		clb(name);
		close();
	};

	const filtered_list = search_by_name(
		list.filter(({ name }) => {
			switch (type) {
				case 'image':
					return !video_types.has(name.substr(-4));
				case 'video':
					return video_types.has(name.substr(-4));
			}
		}),
		search_value
	);

	const on_files_change = (e) => {
		files_context.add(...e.target.files);
	};

	return (
		<MainCard
			sx={{
				'position': 'fixed',
				'top': '50%',
				'right': '50%',
				'transform': 'translate(50%, -50%)',
				'zIndex': 1201,
				'width': 'calc(100vw - 200px)',
				'height': 'calc(100vh - 200px)',
				'boxShadow': '10px 2px 100px rgb(0, 0, 0, 50%)',
				'mt': '0 !important',
				'&:hover': {
					boxShadow: '10px 2px 100px rgb(0, 0, 0, 50%)',
				},
			}}
			title={`Choose ${type}`}
			secondary={
				<IconButton onClick={close} size="large">
					<CloseIcon fontSize="small" />
				</IconButton>
			}
		>
			<Box sx={{ height: 'calc(100vh - 220px)' }}>
				<Stack direction="row" sx={{ alignItems: 'center', mb: 3, gap: '15px' }}>
					<TextField
						value={search_value}
						onChange={(event) => {
							set_search_value(event.target.value);
						}}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon fontSize="small" />
								</InputAdornment>
							),
						}}
						placeholder="Search by title"
						variant="outlined"
						fullWidth
					/>
					<Tooltip title="Add File" onClick={() => file_input_ref.current.click()}>
						<Fab color="primary" size="small" sx={{ boxShadow: 'none', width: 32, height: 32, minHeight: 32, float: 'right' }}>
							<AddIcon fontSize="small" />
						</Fab>
					</Tooltip>
					<input type="file" onChange={on_files_change} hidden ref={file_input_ref} multiple />
				</Stack>
				<Box sx={{ overflow: 'auto', height: 'calc(100vh - 420px)' }}>
					<ImageList variant="masonry" cols={6} gap={8}>
						{filtered_list.map(({ name }) => (
							<ImageListItem key={name} onClick={() => on_submit(name)}>
								<img src={`${CONFIG.api}/app_data/files/${name}`} />
								<ImageListItemBar
									position="below"
									title={name}
									actionPosition="right"
									actionIcon={name === value ? <CheckCircleOutlineOutlinedIcon /> : <></>}
								/>
							</ImageListItem>
						))}
					</ImageList>
				</Box>

				<Divider />
			</Box>
		</MainCard>
	);
};

const FileSelector = ({ on_change, value, type = 'image', read_only, can_clear = false }) => {
	const [is_open, set_is_open] = useState(false);
	const [current, set_current] = useState(value);

	useEffect(() => {
		set_current(value);
	}, [value]);

	useEffect(() => {
		document.body.style.overflowY = is_open ? 'hidden' : 'auto';
	}, [is_open]);

	return (
		<FilesProvider>
			{is_open && (
				<ModalFileSelector
					close={() => set_is_open(false)}
					clb={(value) => {
						on_change(value);
						set_current(value);
					}}
					value={current}
					type={type}
				/>
			)}
			<Box sx={{ display: 'flex', alignItems: 'center', gap: '30px', cursor: read_only ? 'not-allowed' : 'pointer' }}>
				<Avatar
					sx={{
						width: '80px',
						height: '80px',
					}}
					variant="rounded"
					src={current ? `${CONFIG.api}/app_data/previews/big_${current}` : ''}
					actionposition="right"
					onClick={() => (read_only ? null : set_is_open(true))}
				/>

				<Typography component="h3">{current}</Typography>
				{can_clear && current && (
					<IconButton
						color="primary"
						size="large"
						onClick={(event) => {
							event.stopPropagation();
							on_change(undefined);
							set_current(undefined);
						}}
					>
						<DeleteIcon sx={{ fontSize: '1.3rem' }} />
					</IconButton>
				)}
			</Box>
		</FilesProvider>
	);
};

export default FileSelector;
