import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

// project imports
import NavItem from '../NavItem';

// assets
import { IconChevronDown, IconChevronUp } from '@tabler/icons';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //

const NavCollapse = ({ menu, level, parentId }) => {
	const theme = useTheme();
	const [open, setOpen] = useState(false);
	const [selected, setSelected] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClickMini = (event) => {
		setAnchorEl(null);
		setOpen(!open);
		setSelected(!selected ? menu.id : null);
	};

	const openMini = Boolean(anchorEl);
	const { pathname } = useLocation();

	const checkOpenForParent = (child, id) => {
		child.forEach((item) => {
			if (item.url === pathname) {
				setOpen(true);
				setSelected(id);
			}
		});
	};

	// menu collapse for sub-levels
	useEffect(() => {
		setOpen(false);
		setSelected(null);
		if (openMini) setAnchorEl(null);
		if (menu.children) {
			menu.children.forEach((item) => {
				if (item.children?.length) {
					checkOpenForParent(item.children, menu.id);
				}
				if (pathname && pathname.includes('product-details')) {
					if (item.url && item.url.includes('product-details')) {
						setSelected(menu.id);
						setOpen(true);
					}
				}
				if (item.url === pathname) {
					setSelected(menu.id);
					setOpen(true);
				}
			});
		}
	}, [pathname, menu.children]);

	// menu collapse & item
	const menus = menu.children?.map((item) => {
		switch (item.type) {
			case 'collapse':
				return <NavCollapse key={item.id} menu={item} level={level + 1} parentId={parentId} />;
			case 'item':
				return <NavItem key={item.id} item={item} level={level + 1} parentId={parentId} />;
			default:
				return (
					<Typography key={item.id} variant="h6" color="error" align="center">
						Menu Items Error
					</Typography>
				);
		}
	});

	const isSelected = selected === menu.id;

	const Icon = menu.icon;
	const menuIcon = menu.icon ? (
		<Icon strokeWidth={1.5} size={'20px'} style={{ color: isSelected ? theme.palette.secondary.main : theme.palette.text.primary }} />
	) : (
		<FiberManualRecordIcon
			sx={{
				color: isSelected ? theme.palette.secondary.main : theme.palette.text.primary,
				width: isSelected ? 8 : 6,
				height: isSelected ? 8 : 6,
			}}
			fontSize={level > 0 ? 'inherit' : 'medium'}
		/>
	);

	const collapseIcon = <IconChevronUp stroke={1.5} size="16px" style={{ marginTop: 'auto', marginBottom: 'auto' }} />;

	const textColor = theme.palette.mode === 'dark' ? 'grey.400' : 'text.primary';
	const iconSelectedColor = theme.palette.mode === 'dark' ? 'text.primary' : 'secondary.main';

	return (
		<>
			<ListItemButton
				sx={{
					zIndex: 1201,
					borderRadius: '10px',
					mb: 0.5,
					pl: `${level * 24}px`,
					...(level === 1 &&
						theme.palette.mode !== 'dark' && {
							'&:hover': {
								background: theme.palette.secondary.light,
							},
							'&.Mui-selected': {
								'background': theme.palette.secondary.light,
								'color': iconSelectedColor,
								'&:hover': {
									color: iconSelectedColor,
									background: theme.palette.secondary.light,
								},
							},
						}),
				}}
				selected={selected === menu.id}
				onClick={handleClickMini}
			>
				{menuIcon && (
					<ListItemIcon
						sx={{
							minWidth: level === 1 ? 36 : 18,
							color: isSelected ? iconSelectedColor : textColor,
						}}
					>
						{menuIcon}
					</ListItemIcon>
				)}
				<ListItemText
					primary={
						<Typography variant={selected === menu.id ? 'h5' : 'body1'} color="inherit" sx={{ my: 'auto' }}>
							{menu.title}
						</Typography>
					}
					secondary={
						menu.caption && (
							<Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
								{menu.caption}
							</Typography>
						)
					}
				/>

				{openMini || open ? collapseIcon : <IconChevronDown stroke={1.5} size="16px" style={{ marginTop: 'auto', marginBottom: 'auto' }} />}
			</ListItemButton>
			<Collapse in={open} timeout="auto" unmountOnExit>
				{open && (
					<List
						component="div"
						disablePadding
						sx={{
							'position': 'relative',
							'&:after': {
								content: "''",
								position: 'absolute',
								left: '32px',
								top: 0,
								height: '100%',
								width: '1px',
								opacity: theme.palette.mode === 'dark' ? 0.2 : 1,
								background: theme.palette.mode === 'dark' ? theme.palette.dark.light : theme.palette.primary.light,
							},
						}}
					>
						{menus}
					</List>
				)}
			</Collapse>
		</>
	);
};

NavCollapse.propTypes = {
	menu: PropTypes.object,
	level: PropTypes.number,
	parentId: PropTypes.string,
};

export default NavCollapse;
