
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import WysiwygTextarea from './wysiwyg_textarea';

const ControlRender = ({ control, formsList }) => {
	return (
		<>
			{formsList.map(({ name = '', label = '', type = 'text', fullWidth = false, ...rest }, index) => (
				<Controller
					key={index}
					name={name}
					control={control}
					render={({ field }) => {
						switch (type) {
							case 'text':
								return (
									<TextField
										{...field}
										label={label}
										fullWidth={fullWidth}
										variant="outlined"
										{...rest}
									/>
								);
							case 'textarea':
								return (
									<WysiwygTextarea
										{...field}
										label={label}
										placeholder="Write something"
										{...rest}
									/>
								)
							default:
								return null;
						}
					}}
				/>
			))}
		</>
	)
}

export default ControlRender;
