import React from 'react';
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import ActionButton from 'src/ui/action_button';

const MOVE_DOWN = 'move_down';
const MOVE_UP = 'move_up';
const DELETE = 'delete';

const TableComponent = ({ fields, on_delete, on_move_up, on_move_down }) => {
  if (!fields?.length) return <></>;

  return (
    <Grid item xs={12}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ pl: 3 }}>Name</TableCell>
              <TableCell align="right" sx={{ pr: 3 }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map(({ name }, index) => {
              const isFirst = index === 0;
              const isLast = index === fields.length - 1;

              return (
                <TableRow key={index}>
                  <TableCell sx={{ pl: 3 }}>
                    <Typography align="left">{name}</Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Box sx={{ width: '100px' }}>
                      <ActionButton onClick={() => on_move_down(index)} type={MOVE_DOWN} sx={{ float: 'left', display: isLast && 'none' }} />
                      <ActionButton onClick={() => on_move_up(index)} type={MOVE_UP} sx={{ float: 'right', display: isFirst && 'none' }} />
                    </Box>
                    <ActionButton onClick={(event) => on_delete(event, index)} type={DELETE} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default TableComponent;
