import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, List, Typography } from '@mui/material';

// project imports
import NavCollapse from '../NavCollapse';
import NavItem from '../NavItem';

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({ item }) => {
	const theme = useTheme();

	const { pathname } = useLocation();
	const [anchorEl, setAnchorEl] = useState(null);
	const openMini = Boolean(anchorEl);

	const checkOpenForParent = (child, id) => {
		child.forEach((ele) => {
			if (ele.children?.length) {
				checkOpenForParent(ele.children, item.id);
			}
		});
	};

	const checkSelectedOnload = (data) => {
		const childrens = data.children ? data.children : [];
		childrens.forEach((itemCheck) => {
			if (itemCheck.children?.length) {
				checkOpenForParent(itemCheck.children, item.id);
			}
		});
	};

	// keep selected-menu on page load and use for horizontal menu close on change routes
	useEffect(() => {
		checkSelectedOnload(item);
		if (openMini) setAnchorEl(null);
	}, [pathname, item]);

	// menu list collapse & items
	const items = item.children?.map((menu) => {
		switch (menu.type) {
			case 'collapse':
				return <NavCollapse key={menu.id} menu={menu} level={1} parentId={item.id} />;
			case 'item':
				return <NavItem key={menu.id} item={menu} level={1} parentId={item.id} />;
			default:
				return (
					<Typography key={menu.id} variant="h6" color="error" align="center">
						Menu Items Error
					</Typography>
				);
		}
	});

	return (
		<>
			<List
				subheader={
					item.title && (
						<Typography variant="caption" sx={{ ...theme.typography.menuCaption }} display="block" gutterBottom>
							{item.title}
							{item.caption && (
								<Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
									{item.caption}
								</Typography>
							)}
						</Typography>
					)
				}
			>
				{items}
			</List>

			{/* group divider */}
			<Divider sx={{ mt: 0.25, mb: 1.25 }} />
		</>
	);
};

NavGroup.propTypes = {
	item: PropTypes.object,
	lastItem: PropTypes.number,
	remItems: PropTypes.array,
	lastItemId: PropTypes.string,
	parentId: PropTypes.string,
};

export default NavGroup;
