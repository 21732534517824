import { Grid, Button } from '@mui/material';

const FormActionsButtons = ({ on_cancel, on_save }) => {
	return (
		<Grid container spacing={0}>
			<Grid item xs={6}>
				<Button fullWidth variant="text" color="error" onClick={on_cancel} type='submit'>
					Cancel
				</Button>
			</Grid>
			<Grid item xs={6}>
				<Button fullWidth variant="contained" type="submit" onClick={on_save}>
					Save
				</Button>
			</Grid>
		</Grid>
	);
};

export default FormActionsButtons;
