import PropTypes from 'prop-types';

// material-ui
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import componentStyleOverrides from './compStyleOverride';

// project import
import Palette from './palette';
import Typography from './typography';

import customShadows from './shadows';

export default function ThemeCustomization({ children }) {
	const theme = Palette();

	const themeOptions = {
		direction: 'ltr',
		palette: theme.palette,
		mixins: {
			toolbar: {
				'minHeight': '48px',
				'padding': '16px',
				'@media (min-width: 600px)': {
					minHeight: '48px',
				},
			},
		},
		typography: Typography(theme),
		customShadows: customShadows(theme),
	};

	const themes = createTheme(themeOptions);
	themes.components = componentStyleOverrides(themes);

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={themes}>
				<CssBaseline />
				{children}
			</ThemeProvider>
		</StyledEngineProvider>
	);
}

ThemeCustomization.propTypes = {
	children: PropTypes.node,
};
