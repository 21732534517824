import post from './post.js';
import get from './get.js';
import CONFIG from '../config.json';

export default {
	list: ({ session }) => get('files/list', { session }),
	delete: ({ session, name }) => post('files/delete', { session, name }),

	upload: async ({ session, file }) => {
		//TODO emit progress somehow?
		const body = new FormData();
		body.append('file', file);
		body.append('session', session);

		const response = await fetch(`${CONFIG.api}/files/upload`, {
			method: 'POST',
			body,
		});

		let data;
		try {
			data = await response.json();
		} catch (e) {
			console.error(e);
		}

		if (response.ok) {
			return data;
		} else if (response.status === 400) {
			throw data;
		} else {
			throw 'api_error';
		}
	},
};
