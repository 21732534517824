import React, { useContext, useEffect, useState } from 'react';

import UserContext from './user.jsx';

import api_species from '../api/species.js';

const SpeciesContext = React.createContext();


function SpeciesProvider ({ children }) {
	const { session } = useContext(UserContext);
	const [species, set_species] = useState([]);

	useEffect(() => {
		update_list()
	}, [1]);

	const update_list = async () => {
		try {
			const res = await api_species.get_list({ session });
			set_species(res);
			return res
		} catch (e) {
			console.log(e)
		}
	};

	const store = {
		get list () {
			return species;
		},
		async add (data) {
			await api_species.create({ data, session });
		},
		async edit (uid, data) {
			await api_species.update({ uid, data, session });
		},
		async get_by_uid (uid) {
			return await api_species.get_by_id({ uid, session });
		},
		async remove (uid, index) {
			await api_species.delete({ uid, session });
			species.splice(index, 1);
			set_species(species);
		},
		update_list,
	};

	return <SpeciesContext.Provider value={store}>{children}</SpeciesContext.Provider>;
}

export default SpeciesContext;
export { SpeciesProvider };
